import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/layout/seo"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import moment from "moment"
import styled from "styled-components"
import ArticleScroller from "../components/common/article-scroller"

export const query = graphql`
  {
    prismic {
      allNews(first: 10, sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            title
            text
            _meta {
              id
              firstPublicationDate
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10rem;
`

const SelectedNewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 700px;
  max-width: 85vw;
  min-height: 50vh;
`

const SelectedNewsTitle = styled.h2`
  font-size: 3rem;
`

const SelectedNewsPublicationDate = styled.p`
  font-size: 1.4rem;
`

const BreadWrapper = styled.div`
  padding-top: 1rem;
`
const Nyheter = ({ data, location }) => {
  const selected = location.search.split("=").pop()
  const [selectedNews, setSelectedNews] = useState(
    data.prismic.allNews.edges[selected ? parseInt(selected) : 0].node
  )
  const news = data.prismic.allNews.edges.map(n => n.node)

  const handleSelectNews = (article) => {
    setSelectedNews(article)
    window.scrollTo(0, 0)
  }
  return (
    <Layout transparent currentSiteUrl='/nyheter'>
      <SEO title='Nyheter' />
      <Wrapper>
        <SelectedNewsWrapper>
          <SelectedNewsTitle>
            {RichText.asText(selectedNews.title)}
          </SelectedNewsTitle>
          <SelectedNewsPublicationDate>
            {moment(selectedNews._meta.firstPublicationDate).format(
              "YYYY-MM-DD"
            )}
          </SelectedNewsPublicationDate>
          <BreadWrapper>{RichText.render(selectedNews.text)}</BreadWrapper>
        </SelectedNewsWrapper>
        <ArticleScroller
          title="Senaste Nyheterna"
          articles={news}
          selectedArticle={selectedNews}
          handleClick={handleSelectNews}
        />
      </Wrapper>
    </Layout>
  )
}
export default Nyheter
